<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Julio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar relaciones -->
        <section class="d-flex">
            <!-- Filtrar por Pagador -->
            <v-text-field class="me-2 filtro" v-model="buscarPagador" label="Pagador" outlined dense hide-details
                @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por Aseguradora -->
            <v-text-field class="me-2 filtro" v-model="buscarAseguradora" label="Aseguradora" outlined dense hide-details
                @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por Contrato -->
            <v-text-field class="me-2 filtro" v-model="buscarContrato" label="Contrato" outlined dense hide-details
                @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por tipo de movimiento-->
            <v-select class="me-2 filtro" v-model="filtroTipoMovimiento" label="Tipo de movimiento" outlined dense
                hide-details :items="movimientos" :menu-props="{ offsetY: true }">
            </v-select>
            <!-- Filtrar por estado-->
            <v-select class="me-2 filtro" v-model="filtroEstado" label="Estado" outlined dense hide-details :items="estados"
                :menu-props="{ offsetY: true }">
            </v-select>
            <!-- Este botón abre un diálogo para agregar una relación -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoRelacion = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear una relacion -->
        <v-dialog v-model="dialogoRelacion" transition="dialog-bottom-transition" max-width="28rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">Crear relación</span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar la relación -->
                        <v-form lazy-validation>
                            <!-- Campo para ingresar el pagador -->
                            <div class="d-flex filas">
                                <validation-provider v-slot="{ errors }" ref="pagadorValidation" :rules="{ required: true }" class="campos">
                                    <v-autocomplete v-model="crearRelacion.pagador" label="Pagador - Detalle cliente" required dense outlined
                                        :error-messages="errors" :items="opcionesPagadorAseguradora" @change="autocompletarAseguradora"
                                         @input="validarRelacionExistente()" no-data-text="No se encontraron resultados."
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 400 }">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            <!-- Campo que almacena la aseguradora relacionada al pagador -->
                            <div class="d-flex filas">
                                <v-text-field v-model="crearRelacion.aseguradora" label="Aseguradora - Detalle cliente" required dense outlined readonly>
                                </v-text-field>
                            </div>
                            <!-- Campo para ingresar el contrato -->
                            <div class="d-flex filas">
                                <validation-provider v-slot="{ errors }" ref="contratoValidation" :rules="{ required: true }" class="campos">
                                    <v-autocomplete v-model="crearRelacion.contrato" label="Contrato" required dense outlined
                                        :error-messages="errors" :items="opcionesContrato" :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 400 }"
                                        @input="validarRelacionExistente()" no-data-text="No se encontraron resultados.">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            <!-- Campo para ingresar el tipo de movimiento -->
                            <div class="d-flex filas">
                                <validation-provider v-slot="{ errors }" ref="movimientoValidation" :rules="{ required: true }" class="campos">
                                    <v-select v-model="crearRelacion.tipoMovimiento" label="Tipo de movimiento"
                                        :error-messages="errors" :items="opcionesTipoMovimiento" dense outlined
                                        :menu-props="{ offsetY: true }">
                                    </v-select>
                                </validation-provider>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion" text depressed
                                    @click="agregarPagadorContrato()">Guardar</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-data-table class="elevation mt-4" :items="tablaRelacion.items" fixed-header :headers="tablaRelacion.headers"
            :loading="tablaRelacion.loading" :page.sync="tablaRelacion.page" :server-items-length="tablaRelacion.totalPage"
            :footer-props="tablaRelacion.footerProps" :items-per-page.sync="tablaRelacion.itemsPerPage" height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idPaco">
                        <td> {{ item.pagadorAseguradora.pagador.nombrePagador }} - {{ item.pagadorAseguradora.pagador.detalleCliente }}</td>
                        <td> {{ item.pagadorAseguradora.aseguradora.nombreAseguradora }} - {{ item.pagadorAseguradora.aseguradora.detalleCliente}} </td>
                        <td> {{item.contrato.idContrato}} - {{ item.contrato.nombreContrato }}</td>
                        <td>
                            <span v-if="item.tipoMovimiento == 'S'">Salida</span>
                            <span v-if="item.tipoMovimiento == 'F'">Factura</span>
                        </td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td v-if="item.pagadorAseguradora.eliminado === true || item.contrato.eliminado === true" class="text-center">
                            <v-tooltip bottom color="grey">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon color="grey">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Pagador aseguradora<br> y/o contrato inactivo</span>
                            </v-tooltip>
                        </td>
                        <td v-else class="text-center">
                            <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                                        <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar'}}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">
                        {{ mensajeEstado }}
                    </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn :disabled="botonValidacion" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
// validaciones con vee-validate---fin
export default {
    name: "PagadorContrato",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            mensajeEstado: '',
            buscarContrato: '',
            buscarPagador: '',
            buscarAseguradora: '',
            filtroTipoMovimiento: '',
            movimientos: [
                { text: 'Todos', value: '' },
                { text: 'Salida', value: 'S' },
                { text: 'Factura', value: 'F' }
            ],
            filtroEstado: [false, true],
            estados: [
                { text: 'Todos', value: [false, true] },
                { text: 'Activo', value: [false] },
                { text: 'Inactivo', value: [true] },
            ],
            dialogoRelacion: false,
            crearRelacion: {
                contrato: '',
                pagador: '',
                aseguradora: '',
                tipoMovimiento: ''
            },
            botonValidacion: false,
            opcionesPagadorAseguradora: [],
            opcionesContrato: [],
            codigoEmpresa: 1,
            lastmodifiedby: '',
            opcionesTipoMovimiento: [
                { text: 'Salida', value: 'S' },
                { text: 'Factura', value: 'F' }
            ],
            tablaRelacion: {
                loading: false,
                items: [],
                headers: [
                    { text: "PAGADOR", width: "25%", sortable: false },
                    { text: "ASEGURADORA", width: "20%", sortable: false },
                    { text: "CONTRATO", width: "24%", sortable: false },
                    { text: "TIPO DE MOVIMIENTO", width: "9%", sortable: false },
                    { text: "ESTADO", width: "12%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "10%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            relacionSeleccionada: {},
            dialogoCambiarEstado: false,
            userRoles: {},
            roles: Role,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaRelacion.page': function () {
            this.listarPagadorContrato();
        },
        'tablaRelacion.itemsPerPage': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'buscarPagador': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'buscarContrato': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'buscarAseguradora': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'filtroTipoMovimiento': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'filtroEstado': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorContrato();
        },
        'dialogoRelacion': function () {
            if (this.dialogoRelacion) {
                this.listarPagadorAseguradora();
                this.listarContratos();
            }
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.botonValidacion = false;
                }, 500)
            }
        }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         *  Método que lista información en una tabla y se puede paginar los mismos. 
         *  También soporta varios filtros de búsqueda para mejorar la precisión de los datos recuperados.
         */
        listarPagadorContrato() {
            this.tablaRelacion.loading = true;
            this.$http
                .get(`msa-administration/pagadorContrato/listarPagadoresContratos`, {
                    params: {
                        page: this.tablaRelacion.page - 1,
                        size: this.tablaRelacion.itemsPerPage,
                        codigoEmpresa: this.enterprise.code,
                        pagador: `${this.buscarPagador.toLowerCase()}`,
                        aseguradora: `${this.buscarAseguradora.toLowerCase()}`,
                        contrato: `${this.buscarContrato.toLowerCase()}`,
                        estado: `${this.filtroEstado}`,
                        tipoMovimiento: `${this.filtroTipoMovimiento}`,
                    }
                })
                .then(response => {
                    this.tablaRelacion.items = response.data.content;
                    this.tablaRelacion.totalPage = response.data.totalElements;
                    this.tablaRelacion.loading = false;
                }).catch(error => {
                    console.log(error);
                    this.tablaRelacion.loading = false;
                })
        },
        /**
         * Método que lista los pagadores activos relacionados a una aseguradora del back para relacionarlos a un contrato, 
         * solo se ve en el menu el nombre de la relación, pero a la hora de guardar en base de datos solo se guarda el id
         * de la relación pagador-aseguradora.
         */
        listarPagadorAseguradora() {
            this.$http
                .get(`msa-administration/pagadorAseguradora/buscar`, {
                    params: {
                        codigoEmpresa: this.enterprise.code,
                        estado: false
                    }
                })
                .then(response => {
                    response.data.forEach((item) => {
                        this.opcionesPagadorAseguradora.push({
                            text: `${item.pagador.nombrePagador.toUpperCase()} - ${item.pagador.detalleCliente} | ${item.aseguradora.nombreAseguradora.toUpperCase()} - ${item.aseguradora.detalleCliente}`,
                            value: item.idPaas
                        });
                    });
                }).catch(error => {
                    console.log(error);
                })
        },
        /**
         * Este método se utiliza para llenar automáticamente el campo aseguradora en el formulario de creación de relaciones.
         * Busca en la lista de relaciones de pagador-aseguradora la opción que coincide con el pagador seleccionado 
         * y asigna la aseguradora correspondiente al campo de aseguradora en el formulario.
         */
        autocompletarAseguradora() {
            const opcionSeleccionada = this.opcionesPagadorAseguradora.find(item => item.value === this.crearRelacion.pagador);
            if (opcionSeleccionada) {
                const partes = opcionSeleccionada.text.split(' | ');
                this.crearRelacion.aseguradora = partes[1];
            }
        },
        /**
         * Método que lista los contratos activos del back para relacionarlos a un pagador, solo se ve en el menu
         * el nombre del contrato, pero a la hora de guardar en base de datos solo se guarda el id del contrato
         */
        listarContratos() {
            this.$http
                .get(`msa-administration/contrato/contratosActivos`, {
                    params: {
                        idEmpresa: this.enterprise.code,
                        eliminado: false
                    }
                })
                .then(response => {
                    response.data.forEach((item) => {
                        this.opcionesContrato.push({
                            text: `${item.idContrato} - ${item.nombreContrato.toUpperCase()}`,
                            value: item.idContrato
                        });
                    });
                }).catch(error => {
                    console.log(error);
                })
        },
        /**
         * Método que ejecuta la validación de existencia cuando los campos de pagador y contrato estén llenos    
         */
        validarRelacionExistente() {
            if (this.crearRelacion.pagador && this.crearRelacion.contrato) {
                this.$http
                    .get("msa-administration/pagadorContrato/validarExistencia", {
                        params: {
                            pagadorAseguradora: this.crearRelacion.pagador,
                            contrato: this.crearRelacion.contrato,
                            idEmpresa : this.enterprise.code
                        }
                    })
                    .then((response) => {
                        if (response.data !== null) {
                            this.$refs.contratoValidation.errors = ["La relación ya existe."];
                            this.botonValidacion = true;
                        } else {
                            this.$refs.contratoValidation.errors = [];
                            this.botonValidacion = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        },
        /**
         * Método que crea una nueva relación pagador-contrato, los datos se organizan en un objeto(relacion) y se envian 
         * al back a través de la petición.
         */
        agregarPagadorContrato() {
            this.botonValidacion = true;
            const relacion = {
                pagadorAseguradora: {
                    idPaas: this.crearRelacion.pagador
                },
                contrato: {
                    idContrato: this.crearRelacion.contrato
                },
                tipoMovimiento: this.crearRelacion.tipoMovimiento,
                eliminado: false,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby
            };
            this.$http
                .post("msa-administration/pagadorContrato/guardar", relacion)
                .then(() => {
                    this.listarPagadorContrato();
                    this.tablaRelacion.page = 1;
                    this.dialogoRelacion = false;
                    this.clear();
                }).catch(error => {
                    console.log(error);
                })
        },
        /**
         * Abre un diálogo para cambiar el estado de una relación seleccionada
         * @param {Object} item - El objeto que representa la relación seleccionada(datos actuales de la relación)
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.relacionSeleccionada = item;
            this.mensajeEstado = this.relacionSeleccionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
        },
        /**
         * Cambia el estado de una relación y actualiza su estado en la base de datos 
         * mediante una petición put,finalmente actualiza la tabla y cierra el diálogo
         */
        cambiarEstado() {
            this.botonValidacion = true;
            this.relacionSeleccionada.eliminado = (this.relacionSeleccionada.eliminado == false) ? true : false;
            const cambiarEstado = {
                idPaco: this.relacionSeleccionada.idPaco,
                eliminado: this.relacionSeleccionada.eliminado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .put(`msa-administration/pagadorContrato/actualizarEstado`, cambiarEstado)
                .then(() => {
                    this.listarPagadorContrato();
                    this.dialogoCambiarEstado = false;
                }).catch(error => {
                    console.log(error);
                })
        },
        /**
         * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
         */
        clear() {
            this.dialogoRelacion = false;
            this.crearRelacion.contrato = '';
            this.crearRelacion.pagador = '';
            this.crearRelacion.aseguradora = '';
            this.crearRelacion.tipoMovimiento = '';
            this.$refs.observer.reset();
            this.opcionesPagadorAseguradora = [];
            this.opcionesContrato = [];
            this.botonValidacion = false;
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen letras(con y sin tíldes), espacios, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ .-]/)) {
                event.preventDefault();
            }
        },
    },
    mounted() {
        this.listarPagadorContrato();
        this.lastmodifiedby = this.auth.username.toUpperCase();
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    height: 86vh;
}

.filtro {
    width: 20%;
}

.encabezado {
    background-color: #1867c0;
    color: white;
}

.dialogo {
    padding-top: 20px !important;
}

.filas {
    width: 100% !important;
}

.campos {
    width: 100%;
}

.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
</style>